import React, { useCallback, useState } from 'react'
import { useRouter } from 'next/router'
import {
	CaseCategories,
	CaseOrder,
	GetServiceRequestOverviewCasesQuery,
	GetServiceRequestOverviewCasesQueryVariables,
	useGetServiceRequestOverviewCasesLazyQuery,
	useGetServiceRequestStatusItemsQuery
} from '@graphql/graphql'

import { SearchField } from '@damen/ui'
import { CaseColumnKeys } from '@components/ServiceRequestOverview/types'
import GenericOverviewSkeleton from '@skeletons/GenericOverview'
import { useLocale } from '@hooks/useLocale'
import { getStartsFromLocale } from '@utils/getStartsFromLocale'
import GenericOverview from '@components/GenericOverview'
import TwoColRow from '@components/TwoColRow'
import { SortingType } from '@components/GenericOverview/types'
import Spacer from '@components/Spacer'
import Sidebar from '@components/Sidebar'
import StatusExplanation from '@components/UI/StatusExplanation'
import ErrorState from '@components/UI/Error'

import StatusExplanationSkeleton from '@skeletons/StatusExplanation'
import { Content } from '@components/UI/StatusExplanation/styles'
import { removeQueryStrings } from '@utils/removeQueryStrings'
import { DefaultGenericError } from '@components/GenericOverview/components/GenericError/DefaultGenericError'
import ServiceRequestOverviewTable from './ServiceRequestOverviewTable'
import ServiceRequestOverviewFilters, {
	CasesFilters
} from './ServiceRequestOverviewFilters'
import ServiceRequestDownloadButton from './ServiceRequestDownloadButton'
import { ServiceRequestOverviewProps } from './types'

const ServiceRequestOverview = ({
	blok,
	assetId
}: ServiceRequestOverviewProps) => {
	const { locale } = useLocale()
	const [load] = useGetServiceRequestOverviewCasesLazyQuery()
	const {
		data: serviceRequestStatuses,
		loading: caseStatusesLoading,
		error
	} = useGetServiceRequestStatusItemsQuery({
		variables: {
			language: locale,
			starts_with: getStartsFromLocale(locale)
		}
	})

	const items = serviceRequestStatuses?.ServicerequeststatusItems?.items

	const [showStatusPanel, setShowStatusPanel] = useState(false)

	const pageSize = 10

	const router = useRouter()

	const initialStatuses =
		typeof router.query.status === 'string'
			? router.query.status.split(',')
			: router.query.status

	removeQueryStrings(router)

	const iconClickHandler = useCallback(() => {
		setShowStatusPanel(true)
	}, [setShowStatusPanel])

	return (
		<>
			<Sidebar
				isOpen={showStatusPanel}
				onClose={() => setShowStatusPanel(false)}
			>
				{error && !caseStatusesLoading && (
					<Content>
						<ErrorState
							content={
								blok?.errorDescription ??
								'Something went wrong while trying to retrieve case statuses information'
							}
							showBowWave
						/>
					</Content>
				)}
				{caseStatusesLoading && !error && <StatusExplanationSkeleton />}
				{!error && !caseStatusesLoading && items?.length > 0 && (
					<StatusExplanation
						items={items}
						title={blok.statusTitle ?? '[Status Explanation]'}
					/>
				)}
			</Sidebar>
			<GenericOverview<
				GetServiceRequestOverviewCasesQueryVariables,
				GetServiceRequestOverviewCasesQuery,
				CasesFilters
			>
				loadData={useCallback(
					({ filters, searchQuery, orderBy, page }) =>
						load({
							variables: {
								language: locale,
								searchText: searchQuery,
								skip: (page - 1) * pageSize,
								limit: pageSize,
								assetIds: assetId ? [assetId] : undefined,
								assetNames: filters.vessel,
								companyNames: filters.company,
								statuses: filters.status,
								categories:
									filters.category as CaseCategories[],
								order: [
									orderBy?.[CaseColumnKeys.dateFormatted] ===
										SortingType.asc &&
										CaseOrder.CreatedAtAsc,
									(!orderBy ||
										orderBy[
											CaseColumnKeys.dateFormatted
										] === SortingType.desc) &&
										CaseOrder.CreatedAtDesc,
									orderBy?.[CaseColumnKeys.title] ===
										SortingType.asc && CaseOrder.TitleAsc,
									orderBy?.[CaseColumnKeys.title] ===
										SortingType.desc && CaseOrder.TitleDesc
								].filter(Boolean)
							}
						}),
					[load, locale, pageSize, assetId]
				)}
				getTotalPages={(data) => data.cases.total / pageSize}
				initialFilters={{
					status: initialStatuses
				}}
				renderHeader={({
					data,
					filters,
					onChangeFilters,
					searchQuery,
					setSearchQuery
				}) => (
					<>
						<TwoColRow
							mobileLayout="columnReverse"
							spacerBorderBottom={false}
							paddingBottom={false}
							left={
								<SearchField
									value={searchQuery}
									onChange={setSearchQuery}
									placeholder={blok.searchPlaceholder}
								/>
							}
							right={
								data.cases.total > 0 && (
									<ServiceRequestDownloadButton
										variables={{
											language: locale,
											searchText: searchQuery,
											assetIds: assetId
												? [assetId]
												: undefined,
											assetNames: filters.vessel,
											companyNames: filters.company,
											statuses: filters.status,
											categories:
												filters.category as CaseCategories[],
											skip: 0,
											limit: data.cases.total
										}}
										downloadButtonText={
											blok.downloadButtonText
										}
										downloadButtonMobileText={
											blok.downloadButtonMobileText
										}
										downloadError={blok.downloadError}
									/>
								)
							}
						/>
						<Spacer paddingBottom={false}>
							<ServiceRequestOverviewFilters
								facets={data.cases.facets}
								activeFilters={filters}
								vesselFilterLabel={blok.filterVessel}
								companyFilterLabel={blok.filterCompany}
								categoryFilterLabel={blok.filterCategory}
								statusFilterLabel={blok.filterStatus}
								onChangeFilters={onChangeFilters}
								showVesselFilters={assetId === undefined}
								noSearchResultsLabel={blok.searchNoResultsFound}
								filterSearchPlaceholder={blok.filterSearchLabel}
							/>
						</Spacer>
					</>
				)}
				renderTable={({ data, searchWord, orderBy, setOrderBy }) => (
					<ServiceRequestOverviewTable
						items={data.cases.items}
						searchWord={searchWord}
						orderBy={orderBy}
						onOrderByChange={setOrderBy}
						descriptionColumnTitle={
							blok.tableDescription.split('/')[0]
						}
						systemColumnTitle={blok.tableDescription.split('/')[1]}
						categoryColumnTitle={blok.tableCategory}
						dateColumnTitle={blok.tableDate}
						requestedByColumnTitle={blok.tableRequestedBy}
						statusColumnTitle={blok.tableStatus}
						numberColumnTitle={blok.tableNumber}
						onIconClick={iconClickHandler}
					/>
				)}
				renderError={({ type, searchQuery }) => (
					<DefaultGenericError
						type={type}
						searchQuery={searchQuery}
						translations={blok}
					/>
				)}
				skeleton={<GenericOverviewSkeleton filterAmount={6} />}
			/>
		</>
	)
}

export default ServiceRequestOverview
